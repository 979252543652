<template>
  <a-row class="content-title text-center">
    <h2 class="gray-text">Выберите раздел</h2>
  </a-row>
</template>

<script>
export default {}
</script>

<style></style>
